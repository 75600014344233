import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { BiPalette } from "react-icons/bi";
import { DiAndroid, DiAtom } from "react-icons/di";
import { FiServer } from "react-icons/fi";
import {
  headingAnimation,
  sectionBodyAnimation,
} from "../../../hooks/useAnimation";
import { BottomLine } from "../../../components";
import { SiAndroid, SiAndroidstudio, SiAppstore, SiBmcsoftware, SiElectron } from "react-icons/si";
import { FaAndroid, FaAppStore, FaAppStoreIos } from "react-icons/fa";
import { MdPhoneAndroid } from "react-icons/md";

const Service = () => {
  const [ref, inView] = useInView();
  const [viewDiv, setViewDiv] = useState(false);
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      setViewDiv(true);
    } else {
      setViewDiv(false);
    }
  }, [inView, animation]);

  const services = [
    {
      id: 1,
      title: "Web Development",
      icon: <DiAtom />,
      description:
        "At Fillosoft, we are passionate about crafting captivating digital experiences that drive your business forward. Our web development services are at the heart of our commitment to innovation and excellence"

    },
    {
      id: 2,
      title: "Desktop Application Development",
      icon: <SiElectron style={{color:'#00ABD7',fontWeight:'bold'}} />,
      description:
        "Our team of experienced developers specializes in creating desktop applications for various operating systems, including Windows, macOS, and Linux.",
    },
    {
      id: 3,
      title: "Products",
      icon: <SiBmcsoftware />,
      description:
        "At Fillosoft, we take pride in offering a diverse range of high-quality products designed to meet the unique needs of our customers. Our product lineup is a testament to our commitment to excellence and innovation",
    },
    {
      id: 4,
      title: "Web Management",
      icon: <FiServer />,
      description:
        "We will manage any kinds of website. Data Management, Component management, Service management and so on.",
    },
    {
      id: 5,
      title: "Mobile Apps Development",
      icon: <MdPhoneAndroid />,
      description:
      "At Fillosoft, we specialize in crafting cutting-edge mobile applications that propel your business into the digital age",
    },
    {
      id: 6,
      title: "Database Management",
      icon: <FiServer />,
      description:
        "Connecting Web Database to the server. Fixing All kinds of issue with related to database with database advanced operators.",
    },
  ];
  return (
    <div className="py-20 parent"
    // style={{ backgroundColor: "#fff" }}
    >
      <motion.div
        className="mb-12"
        initial="hidden"
        animate={viewDiv && "visible"}
        variants={headingAnimation}
        
      >
        <h3 className="text-center text-neutral">What We Provide</h3>
        <h1 className="text-4xl font-semibold text-center">
          Our <span className="text-primary">Services</span>
        </h1>
        <BottomLine />
      </motion.div>

      <motion.div
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
        ref={ref}
        initial="hidden"
        animate={viewDiv && "visible"}
        variants={sectionBodyAnimation}
        
      >
        {services?.map((service) => (
          <div
          style={{border:'1px solid #00ABD7'}}
            key={service.id}
            className={`${service.id % 2 === 0
              ? "bg-[white] shadow-lg "
              : "bg-[white] shadow-lg"
              } rounded-lg p-6 hover:shadow-primary cursor-pointer duration-300 hover:-translate-y-3  duration-300 `}
          >
            <div className="mb-4 text-center">
              <span className="inline-block text-5xl text-primary hover:-translate-y-1.5  duration-300 ">
                {service.icon}
              </span>
            </div>
            <h2 className="mb-4 text-2xl font-semibold text-center">
              {service.title}
            </h2>
            <p className="text-primary">{service.description}</p>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Service;
