import React from "react";
import { Link } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { RiFolderInfoFill } from "react-icons/ri";
import TypeAnimation from "react-type-animation";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import coding from "../../../assets/coding.json";
import "../../../pages/shared/Shared.css";
import { SecondaryBtn } from "../../../components";

const Banner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: coding,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="parent min-h-[75vh] flex flex-col-reverse lg:flex-row items-center justify-between">
      <motion.div
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ duration: 1 }}
      >
        <h2 className="text-neutral text-xl font-medium translate-y-[-90%] sm:translate-y-[-0%]">Welcome To</h2>
        <h1 className="text-4xl font-semibold mb-0 translate-y-[-50%] sm:translate-y-[-0%]">Fillosoft</h1>
        <div className="my-4">
          <TypeAnimation
            className="text-2xl text-primary font-bold translate-y-[-80%] sm:translate-y-[-0%]"
            cursor={true}
            sequence={[
              "Welcome to Fillosoft, ",
              2000,
              "your digital gateway to web excellence!",
              2000,
            ]}
            wrapper="div"
            repeat={Infinity}
          />
        </div>
        <p className="text-neutral max-w-xl mb-6 font-medium translate-y-[-20%] sm:translate-y-[-0%] mt-10">
          At Fillosoft, we are passionate about creating innovative and dynamic web solutions that empower
          businesses and individuals to thrive in the digital landscape. We are thrilled to have you visit
          our website and explore the world of web development with us.
          Our team of dedicated professionals is committed to delivering top-notch
          web development services, tailored to your unique needs and goals.
          Whether you're looking to establish a strong online presence, revamp your existing website,
          or embark on an exciting e-commerce journey, Fillosoft is here to make your vision a reality.
        </p>

        <div className="flex items-center translate-y-[-60%] sm:translate-y-[-0%]">
          <Link to="/about" className="ml-4">
            <SecondaryBtn >
              <span className="text-primary hover:text-white ">About Us</span>
              
            </SecondaryBtn>
          </Link>
        </div>
      </motion.div>
      <motion.div
        className="w-full md:w-1/2 "
        initial={{ x: "100vw" }}
        animate={{ x: 0 }}
        transition={{ duration: 1 }}
      >
        <Lottie options={defaultOptions} height="90%" width="90%" />
      </motion.div>
    </div>
  );
};

export default Banner;
