const Reviews = [
  {
    id: 1,
    name: "Assam Basketball Association",
    bio: "Chief patron Of Assam Basketball Association",
    img: "https://as2.ftcdn.net/v2/jpg/02/29/75/83/1000_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    description:
      "I am very impressed by their work.They developed the Assam Basketball Association Website with outmost professionalism.",
    rating: 5.0,
  },
  {
    id: 2,
    name: "Unknown",
    bio: "TACSOL User",
    img: "https://as2.ftcdn.net/v2/jpg/02/29/75/83/1000_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    description:
      "I have been using their product TACSOL from 3 years and it's the best in the market right now with 24x7 customer support",
    rating: 5.0,
  },
  {
    id: 3,
    name: "Unknown",
    bio: "Storeman User",
    img: "https://as2.ftcdn.net/v2/jpg/02/29/75/83/1000_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    description:
      "I have been using their product STOREMAN a billing and inventory management software and it's the best in the market right now with 24x7 customer support",
    rating: 5.0,
  },
  {
    id: 4,
    name: "Democracy Live ",
    bio: "Democracy Live 24x7 News Portal",
    img: "https://as2.ftcdn.net/v2/jpg/02/29/75/83/1000_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    description:
      "I was finding someone to help me to build a website for our news portal. He made us our official portal for democracy live 24x7 news portal. I am satisfied with their work.",
    rating: 5.0,
  },
];

export default Reviews;
