
const Items = [
  {
    id: 1,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954636/fillosoft/tacsol/jwsbpd5kdwsra3wtwmwh.png'],
    img: [
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954636/fillosoft/tacsol/jwsbpd5kdwsra3wtwmwh.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954636/fillosoft/tacsol/tnstzvsuspekxahtopa5.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954636/fillosoft/tacsol/vtmzwhmxmp8xwyw6zpmp.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954637/fillosoft/tacsol/onm6dzmbacb0d3n9q9mm.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954637/fillosoft/tacsol/iuzsts5qtnghdaqufrk8.png'
     
    ],
    features: [
      "Member Management",
      "Account Management",
      "Recovery Agents Management",
      "Loan Management",
      "Profit And Loss Management",
      "Cash Flow Report Management",
      "Savings and Investment Options",
      "Transaction Automation:",
      "Financial Reporting",
      "Regulatory Compliance",
      "Savings and Investment Options",
      "Security and Permissions",
      "Member Communication",
      "Data Backup and Recovery",
      "User-Friendly Interface",
      "Scalability",
      "Transaction Messages on Mobile",
      "Tacsol is a comprehensive thrift and credit cooperative society management software designed to improve efficiency, enhance member services, and streamline financial operations while ensuring compliance and data security.",
    ],
    technologies: [
      "React",
      "CSS",
      "Material Ui",
      "React Hooks",
      "React icons",
      "Email JS",
      "Framer Motion",
      "PHP",

    ],
    title: "TACSOL",
    description:
      "Tacsol is a comprehensive software solution designed to streamline the operations of thrift and credit cooperative societies. This powerful management tool is tailored to meet the specific needs of cooperative financial institutions, offering a range of features to enhance efficiency and effectiveness.With Tacsol, your cooperative society can efficiently manage member accounts, track deposits, withdrawals, and loans, and maintain a detailed financial record. The software simplifies the administrative tasks associated with membership management, automates transactions, and provides real-time financial insights. This allows for better decision-making and improved member services.Tacsol not only ensures compliance with regulatory requirements but also enhances the security and transparency of financial operations. Whether you are a small cooperative society or a large organization, Tacsol provides the tools and support needed to optimize your financial management processes, making it an indispensable resource for the modern cooperative financial institution.",
    category: "business",
    liveLink: "https://tacsol.fillosoft.com/",
    codeLink: "https://www.fillosoft.com/",
  },
  {
    id: 2,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954613/fillosoft/storeman/dfveiqptwko9fejx4p0e.png'],
    img: [
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954613/fillosoft/storeman/dfveiqptwko9fejx4p0e.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954613/fillosoft/storeman/otpp6pis7cu4archyawj.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954612/fillosoft/storeman/pcimbbejm5hoqheedgmr.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954612/fillosoft/storeman/qdg1nfb2mwtt2a4cqqmj.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954612/fillosoft/storeman/ve9vqzqgedm2hm2xyxcb.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954611/fillosoft/storeman/q9fwwrpfyapqpfd426rc.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954611/fillosoft/storeman/xxjdcrzxkkqldguqq6ck.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954611/fillosoft/storeman/bpfg5vasfed1cewtbvt1.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954610/fillosoft/storeman/ocwfxayjrhjjdngxgdfa.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954610/fillosoft/storeman/jopchzkoixlyslcvpfjf.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954610/fillosoft/storeman/wozcm7aoud3yacug8vvd.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954610/fillosoft/storeman/sx7vwl73skxhnjnkshup.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954609/fillosoft/storeman/qozf8m7vrfiicqucovdf.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954609/fillosoft/storeman/aqpkykbcdm0qqyh0fnli.png',
      'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954608/fillosoft/storeman/mesze5hw8ulgdsbizfne.png'
    ],
    features: [
      "Inventory Tracking",
      "Product Management",
      "Order Management",
      "Sales Management",
      "Barcode Scanning",
      "Inventory Replenishment",
      "Multi-location Support",
      "Inventory Valuation",
      "Reporting and Analytics",
      "User Access Control",
      "Integration Capabilities",
      "Customization",
      "Alerts and Notifications",
      "Data Backup and Security",
      "User-Friendly Interface",
      "Invoice",
      "Advanced Security",
      "Financial Report",
      "Cash Flow Report",
      "Profit And Loss Report",
      "Storeman is a versatile store and inventory management system that empowers businesses to efficiently manage their stock, reduce costs, and improve overall operational efficiency. Whether you're a retail store, warehouse, or distribution center, Storeman helps you stay in control of your inventory and streamline your operations.",

    ],
    technologies: [
      "React",
      "React Hooks",
      "React Icons",
      "Axios",
      "CSS",

      "Electron.Js",
      "Php",
      "MySQL",
      "cPanel",
      "Deskto+Web Application",

    ],
    title: "STOREMAN",
    description:
      "Storeman is a sophisticated store and inventory management system designed to simplify and optimize the complex task of inventory control for businesses of all sizes. This powerful software offers a comprehensive range of features to streamline your inventory management processes, ensuring efficiency, accuracy, and cost-effectiveness.With Storeman, you can effortlessly keep track of your stock levels, manage product orders, and monitor sales. This system provides real-time visibility into your inventory, helping you make informed decisions and prevent stockouts or overstock situations.Storeman is highly customizable, allowing you to tailor it to your specific business needs. It offers a user-friendly interface for easy navigation and operation, ensuring that both small businesses and larger enterprises can benefit from its capabilities.Whether you're a retail store, warehouse, or any business that deals with inventory, Storeman can help you take control of your stock, reduce operational costs, and improve overall efficiency. It's the ultimate solution for modern businesses looking to optimize their inventory management processes.",
    category: "business",
    liveLink: "https://storeman.in/",
    codeLink: "https://www.fillosoft.com/",
  },
  {
    id: 3,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954355/fillosoft/democracy/umtd02xxkwxoqqut8a3c.png'],
    img: [
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954355/fillosoft/democracy/umtd02xxkwxoqqut8a3c.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954354/fillosoft/democracy/y1vnegvsg7mbbfu5fvcr.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954354/fillosoft/democracy/rbprqfycyd5xsnkotfc9.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954353/fillosoft/democracy/mly22gmtv8rs4ezridci.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954353/fillosoft/democracy/dtknjh9pochj2kjesfq4.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954352/fillosoft/democracy/umnfx2rz6opybkbkcwno.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954352/fillosoft/democracy/mgsiyjvttli2ygbxqijp.png'

    ],
    features: [
      "Real-time News Updates",
      "Diverse News Categories",
      "Multimedia Content",
      "User Engagement",
      "Search and Navigation",
      "Mobile Responsiveness",
      "Social Media Integration",
      "Adaptive Design",
      "Watch the videos on Youtube",
      "Data Privacy and Security",
      "Analytics and Insights",
      "DarkMode",
      "Admin Management",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Router Dom",
      "Axios",
      "Material UI",
      "PHP",
      "MySQL",
    ],
    title: "News Portal(Democracy Live 24x7)",
    description:
      "DemocracyLive24x7 is a cutting-edge news portal website project that I developed to serve as a beacon of democratic values and the dissemination of timely and accurate information. This platform is dedicated to delivering real-time news, insights, and analysis from around the world, with a particular focus on promoting the principles of democracy.The DemocracyLive24x7 website is designed to provide a user-friendly and engaging experience for visitors. It offers a well-organized interface, making it easy for users to access the latest news, feature articles, and multimedia content. The website covers a wide range of topics, from politics and governance to social issues, international relations, and more.",
    category: "personal",
    liveLink: "https://www.democracylive24x7.com/",
    codeLink: "https://www.fillosoft.com/",
  },
  {
    id: 4,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954272/fillosoft/basketball/pvh1x9wn1rfkxmk2d9co.png'],
    img: [
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954272/fillosoft/basketball/pvh1x9wn1rfkxmk2d9co.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954272/fillosoft/basketball/tusn9kvcofcvel2o9fdw.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954274/fillosoft/basketball/tcy2qlpbnh0obocjetnf.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954273/fillosoft/basketball/savjxbc3x3htzdktmdkk.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954273/fillosoft/basketball/nnduo2d5vjxsrwzllrds.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954272/fillosoft/basketball/bdgruipoinoec5cospee.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954272/fillosoft/basketball/pqahb1yfv6eiwwvdgwq0.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954275/fillosoft/basketball/vdv1ymnmpimzurqbsumz.png'

    ],
    features: [
      "The web application created for the Assam Basketball Association comprises both a user-friendly website and an intuitive admin panel, offering a comprehensive set of features for efficient management and community engagement.",
      "Real-time Updates",
      "Player and Team Registration",
      "Event Information",
      "Statistics and Standings",
      "Coaching Resources",
      "Referee Training",
      "Interactive Forums",
      "News and Articles",
      "Photo and Video Galleries",
      "Social Media Integration",
      "Change admin and employee passwords",
      "Responsive Design",

      "User Management",
      "Event Management",
      "Player and Team Managemen",
      "Statistical Tracking",
      "Content Management",
      "Forum and Discussion Monitoring",
      "Resource Management",
      "Analytics and Reporting",
      "The Assam Basketball Association web application, with its website and admin panel, is a powerful tool that not only promotes the sport but also enhances the management and engagement within the basketball community in Assam. It represents a holistic approach to basketball development and community building."
    ],
    technologies: [
      "React",
      "React Hooks",
      "Material Ui",
      "NextJs",
      "PHP",
      "MySQL",
      "cPanel",
      "Bootstrap",
      "CSS",
      "Javascript",
      ,
    ],
    title: "Assam basketball Association",
    description:
      "The web application developed for the Assam Basketball Association is a cutting-edge and indispensable tool designed to revolutionize the management and promotion of basketball in the region. This innovative platform serves as the digital hub for all things basketball in Assam, catering to players, coaches, officials, and basketball enthusiasts alike.The Assam Basketball Association web application provides a range of features and functionalities that enhance the overall experience of the basketball community in the region. Users can access real-time information on tournaments, schedules, and results, allowing them to stay up-to-date with the latest in the basketball scene. The application also facilitates player registrations, team management, and statistical tracking to streamline the organization of basketball events.One of the standout features of this application is its commitment to grassroots development. It provides resources for coaching, player development, and referee training, fostering the growth of basketball at all levels. Additionally, the platform encourages community engagement through forums, discussions, and interactive content, nurturing a passionate basketball community in Assam.The Assam Basketball Association web application represents a powerful blend of technology and sports, creating a digital space where the love of the game thrives. It is a testament to the commitment to promoting basketball, enhancing transparency, and supporting the growth of the sport in the region.",
    category: "personal",
    liveLink: "https://assambascketballassociation.com/",
    codeLink: "https://www.fillosoft.com/",
  },
  // {
  //   id: 5,
  //   mainImage: [basketball1],
  //   img: [
  //     [basketball1],
  //     [basketball2],
  //     [basketball3],
  //     [basketball4],
  //     [basketball5],
  //     [basketball6],
  //     [basketball7],
  //     [basketball8],

  //   ],
  //   features: [
  //     "Student Information Management ",
  //     "Academic Record Keeping ",
  //     "Attendance Tracking ",
  //     "Scheduling and Timetables ",
  //     "Communication Tools ",
  //     "Staff Management ",
  //     "Financial Management ",
  //     "Library Management ",
  //     "Inventory and Asset Management ",
  //     "Parent and Guardian Portal ",
  //     "Admissions and Enrollment ",
  //     "Security and Access Control ",
  //     "Customization and Integration ",
  //     "Reports and Analytics ",
  //     "Mobile Accessibility ",
  //     "The School Management System is a comprehensive solution that simplifies administrative tasks, enhances communication, and promotes efficient academic management, ultimately improving the overall educational experience for students, teachers, and school administrators. ",

  //   ],
  //   technologies: [
  //     "React",
  //     "React Hooks",
  //     "React Router Dom",
  //     "Axios",
  //     "Bootstrap",
  //     "PHP",
  //     "cPanel",
  //     "MySQL",
  //     "next.js",

  //   ],
  //   title: "School Management System",
  //   description:
  //     "The School Management System is a comprehensive and efficient project designed to streamline and enhance the administrative and academic operations of educational institutions. This digital solution is tailored to meet the specific needs of schools, providing a wide range of features to simplify tasks for educators, administrators, students, and parents.The School Management System empowers educational institutions to effectively manage student information, academic records, staff data, and administrative tasks. It offers tools for attendance tracking, grading, scheduling, and communication, all accessible through a user-friendly interface. This system not only enhances the efficiency of school operations but also improves communication between stakeholders, ensuring a smooth learning experience for students and a well-organized environment for educators.With the School Management System, schools can embrace modern technology to automate routine tasks, store and access data securely, and promote transparency and accountability. It is a valuable asset for the educational sector, making it easier for schools to focus on what matters most – providing quality education and fostering student success.",
  //   category: "business",
  //   liveLink: "https://sttiss.vercel.app/",
  //   codeLink: "https://www.fillosoft.com/",
  // },














  {
    id: 6,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954193/fillosoft/aims/zxrfli37abyxbiiaxyvg.png'],
    img: [
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954193/fillosoft/aims/zxrfli37abyxbiiaxyvg.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954191/fillosoft/aims/bai6zo7jdon3cfy5aias.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954191/fillosoft/aims/g5lnquvjk1mrp443xxnf.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954191/fillosoft/aims/jwur1ieiqbsuol19a9od.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954191/fillosoft/aims/w61cpc1lhour1r5zk20c.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954190/fillosoft/aims/hmaazlblkaa0sdyojvoq.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954190/fillosoft/aims/fb3icpfxo2fexhce39pk.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954190/fillosoft/aims/y2vbrnxwcu3uj7uolent.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954190/fillosoft/aims/q0h6sah4udlahdsep3ze.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954190/fillosoft/aims/q2i5nvl0ly2m4mctkcye.png'

    ],
    features: [
      "User Management",
      "Referral Tracking",
      "Commission Calculation",
      "Marketing Material Repository",
      "Communication Tools",
      "Analytics and Reporting",
      "Secure Access",
      "Student Enrollment",
      "Course Managemen",
      "Instructor Management",
      "Attendance Tracking",
      "Fee Management",
      "Resource Management",
      "Examination and Grading",
      "Communication Tools",
      "Mobile-Friendly",
      "Data Backup and Security",
      "Custom Reporting",
      "Integration with Payment Gateways",

    ],
    technologies: [
      "Java",
      "PHP",
      "HTML",
      "CSS",
      "Bootstrap",
      "MySQL",
    ],
    title: "AIMS Computer Coaching",
    description:
      "Our Network Marketing Management System is a comprehensive software solution designed to streamline and optimize the network marketing processes within a computer coaching centre. This innovative project offers a user-friendly and efficient platform that empowers the coaching centre to effectively manage their network marketing operations.",
    category: "personal",
    liveLink: "https://aimscomeduc.com/index.html",
    codeLink: "https://www.fillosoft.com/",
  },

  {
    id: 7,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954231/fillosoft/bani/h6ymjejpjrxgdsqnnse2.png'],
    img: [
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954231/fillosoft/bani/h6ymjejpjrxgdsqnnse2.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954231/fillosoft/bani/ek4mgdzloti5yppqpagw.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954230/fillosoft/bani/taaqsknsjangkdcst1gr.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954230/fillosoft/bani/ym1tuxqfzitb30ibxcym.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954230/fillosoft/bani/rqhrmuwpdlykszlrarj8.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954229/fillosoft/bani/k2exk377vogv7nldmhgy.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954229/fillosoft/bani/dstel80ctksl2w5uua03.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954229/fillosoft/bani/ukbvizqo4rceor3na1hq.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954229/fillosoft/bani/qiudxatjfkocdigsstw2.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954229/fillosoft/bani/beldbz1ftnvhxh6uvsgp.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954229/fillosoft/bani/gyagvbqsxinelzjtayhk.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954228/fillosoft/bani/lydvyk1jjffz22hz2rkg.png'

    ],
    features: [
      "Homepage",
      "About Us",
      "Admissions",
      "Academics",
      "Faculty and Staf",
      "Student Resources",
      "Parent Portal",
      "News and Events",
      "Contact Us",
      "Gallery",
      "Alumni",
      "Blog or News Feed",
      "School Calendar",
      "Social Media Integration",
      "Accessibility Features",
      "Mobile Responsiveness",


    ],
    technologies: [
      "PHP",
      "HTML",
      "CSS",
      "Bootstrap",
      "MySQL",
    ],
    title: "Bani Bikash Academy",
    description:
      "Our School Website project aims to create a modern, user-friendly, and informative online platform for our educational institution. This website will serve as a digital gateway for students, parents, teachers, and the community, offering a wealth of information and resources while promoting a positive online presence for our school.",
    category: "personal",
    liveLink: "https://banibikashacademy.in/index.html",
    codeLink: "https://www.fillosoft.com/",
  },

  {
    id: 8,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954382/fillosoft/ethnic/yqzb1mkhvqij7obaq0j8.png'],
    img: [
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954382/fillosoft/ethnic/yqzb1mkhvqij7obaq0j8.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954386/fillosoft/ethnic/yausr4r3nj70n3hz7auc.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954385/fillosoft/ethnic/ibxteykav5kaaussmvut.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954384/fillosoft/ethnic/xvwrtgk0mtf72bfpkqs1.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954384/fillosoft/ethnic/zxobtob8hawyirmovfrt.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954384/fillosoft/ethnic/yqrxya03ryjbtwj5cxe8.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954383/fillosoft/ethnic/ob73q9zjjvohbyftldgi.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954382/fillosoft/ethnic/uqbyoc6iokpgb44xrefu.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954382/fillosoft/ethnic/zkyhxci63lxcriqujs5f.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954382/fillosoft/ethnic/sssmf0kqaa9pgraq73t5.png',


    ],
    features: [
      "Homepage",
      "About the Resort",
      "Accommodations",
      "Dining and Cuisine",
      "Cultural Experiences",
      "Nature and Adventure",
      "Spa and Wellness",
      "Photo Gallery",
      "Booking and Reservation",
      "Special Offers",
      "Reviews and Testimonials",
      "Contact and Location",
      "Contact and Location",
      "Language Options",
      "Social Media Integration",
      "Mobile Responsiveness",



    ],
    technologies: [
      "PHP",
      "HTML",
      "CSS",
      "Bootstrap",
      "MySQL",
    ],
    title: "Ethnic Village Resort",
    description:
      "Our Ethnic Resort Website project is dedicated to crafting a captivating online platform that reflects the unique cultural and natural beauty of our ethnic resort. This website will serve as a virtual gateway for travelers seeking an authentic and immersive experience in a culturally rich and picturesque environment.",
    category: "personal",
    liveLink: "https://www.ethnicvillageresort.com/index.html",
    codeLink: "https://www.fillosoft.com/",
  },

  {
    id: 9,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954415/fillosoft/iirdda/bwpujp4brcyy5rmkxzy2.png'],
    img: [
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954415/fillosoft/iirdda/bwpujp4brcyy5rmkxzy2.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954417/fillosoft/iirdda/yyqdfh2hc8dyzgq2dhjo.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954416/fillosoft/iirdda/toa5mzqnaq5m6nkla0av.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954415/fillosoft/iirdda/ahszjci9palkqrxrzn2b.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954415/fillosoft/iirdda/x3bwbk3xphfxfigoymzb.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954415/fillosoft/iirdda/wtcex7dnbqcjtjqk7ocr.png'

    ],
    features: [
      "Research Initiatives",
      "Homepage",
      "About Us",
      "Resources",
      "Success Stories",
      "Community Suppor",
      "Events",
      "Contact Us",
      "News and Updates",
      "Partnerships",
      "Accessibility",
      "Mobile responsive",




    ],
    technologies: [
      "ReactJs",
      "Material Ui",
      "PHP",
      "HTML",
      "CSS",
      "Bootstrap",
      "MySQL",
    ],
    title: "Indian Institute of Research and Development for Differently Abled (IIRDDA)",
    description:
      "Our Physically Disabled Research and Development website project is a dynamic online platform dedicated to advancing the lives and well-being of individuals with physical disabilities. This website serves as a hub for our organization's groundbreaking research, development initiatives, and resources aimed at enhancing the quality of life for differently-abled individuals.",
    category: "personal",
    liveLink: "https://iirdda.ac.in/",
    codeLink: "https://www.fillosoft.com/",
  },

  {
    id: 10,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954535/fillosoft/mayfair/x865fto5ek7ykr4wcnbh.png'],
    img: [
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954535/fillosoft/mayfair/x865fto5ek7ykr4wcnbh.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954539/fillosoft/mayfair/lyhroksd6qvzwyas9svg.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954535/fillosoft/mayfair/i3bdqvjrv9ryspkhn3vf.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954534/fillosoft/mayfair/egzytrzjsnpxljqjsr5r.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954534/fillosoft/mayfair/h0gq8assuga6abwau9o7.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954533/fillosoft/mayfair/dlmb7ylzpfpvdxh339w3.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954533/fillosoft/mayfair/ptqm8w6mdcrnlj5llyy8.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954533/fillosoft/mayfair/nzwbipwaeuvbrtxh9soo.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954532/fillosoft/mayfair/t6urtoyw0ec7ixl40yan.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954532/fillosoft/mayfair/aixppemnfayjms0a6uqz.png'


    ],
    features: [
      "Homepage",
      "About Us",
      "Services",
      "Portfolio",
      "Contact Information",
      "Client Testimonials",
      "Safety and Quality Assurance",
      "Blog or News",
      "Team",
      "Partnerships",
      "Accreditations and Awards",
      "FAQs",
    ],
    technologies: [
      "PHP",
      "HTML",
      "CSS",
      "Bootstrap",
      "MySQL",
    ],
    title: "Mayfair developers And Builders",
    description:
      "Our Construction Company Static Website project is dedicated to creating a professional and informative online presence for our construction business. This website will serve as a digital showcase of our projects, services, and expertise, offering a clear representation of our capabilities and commitment to quality in the construction industry.",
    category: "personal",
    liveLink: "https://mayfairdevs.com/index.html#[object%20Object]",
    codeLink: "https://www.fillosoft.com/",
  },

  {
    id: 11,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954572/fillosoft/samwoode/w0wvzlmgyekysistuxal.png'],
    img: [
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954572/fillosoft/samwoode/w0wvzlmgyekysistuxal.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954570/fillosoft/samwoode/onz6z8gvhld6tqjuaoh5.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954570/fillosoft/samwoode/aep1gjzjmrqbdou37ycx.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954569/fillosoft/samwoode/wmlm8cpxh28jol28mfkd.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954569/fillosoft/samwoode/cxutik1uwewrg8r13wu4.png',
     'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954569/fillosoft/samwoode/sifdjmmjfd5h9woiu3yf.png',
     


    ],
    features: [
      "User Registration",
      "E-commerce Platform",
      "Product Catalog",
      "Referral and Networking",
      "Compensation Plan",
      "Earning Dashboard",
      "Marketing Materials",
      "Payment Gateway",
      "Reports and Analytics",
      "Customer Support",
      "Push Notifications",
      "Social Sharing",
      "Training and Onboarding",
      "Performance Tracking",
      "Gamification",
      "Entrepreneurial Opportunity",
      "Earning Potentia",
      "E-commerce Integration",
      "Financial Independence",
      "The Network Marketing Website and Application project aims to offer a comprehensive, accessible, and lucrative opportunity for individuals to enter the world of network marketing, sell quality products, and build thriving networks for financial success",
     
    ],
    technologies: [
      "React",
      "nextJs",
      "Material Ui",
      "React Hooks",
      "React Router Dom",
      "Axios",
      "Bootstrap",
      "PHP",
      "cPanel",
      "MySQL",
      "next.js",

    ],
    title: "Samwoode Health Care",
    description:
      "Our Network Marketing Website and Application project is a dynamic platform that combines e-commerce with a robust network marketing system. It offers users the opportunity to sell products, build a network of distributors, and earn points and money through a structured compensation plan. This innovative solution empowers individuals to become entrepreneurs and achieve financial success through network marketing.",
    category: "personal",
    liveLink: "https://www.swhc.in/",
    codeLink: "https://www.fillosoft.com/",
  },


  {
    id: 12,
    mainImage: ['https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954308/fillosoft/bazar/qifijzrsnmtvagm60wxo.png'],
    img: [
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954308/fillosoft/bazar/qifijzrsnmtvagm60wxo.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954309/fillosoft/bazar/gcuuuqfgsifumpwts81w.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954307/fillosoft/bazar/f8fnyebwehkf85l0nji8.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954306/fillosoft/bazar/hmjoyp8fp4blu8ab8rb4.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954306/fillosoft/bazar/j11iy6gzi9recjdgzxdy.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954306/fillosoft/bazar/as5tswqgbmeh054pm1jc.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954306/fillosoft/bazar/pltynmtzrqjwhknt4y0u.png',
    'https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699954305/fillosoft/bazar/swnbdkyadxbte4n3k2ul.png'


    ],
    features: [
      "User-Friendly Interface",
      "Product Catalog",
      "Customized Shopping Lists",
      "Real-Time Inventory",
      "Secure Payment",
      "Delivery Scheduling",
      "Order Tracking",
      "Promotions and Discounts",
      "Customer Support",
      "User Reviews and Ratings",
      "Convenience",
      "Time-Saving",
      "Freshness Guaranteed",
      "Contactless Shopping",



    ],
    technologies: [
      "React Native",
      "Material Ui",
      "React Hooks",
      "React Router Dom",
      "Axios",
      "Bootstrap",
      "PHP",
      "cPanel",
      "MySQL",

    ],
    title: "Aamar Bazaar",
    description:
      "Aamar Bazaar Is An Online Grocery Ordering Android App is your convenient and efficient solution for hassle-free grocery shopping from the comfort of your mobile device. This user-friendly app connects you to a wide selection of fresh produce, pantry staples, and household items, allowing you to shop, customize your order, and have groceries delivered to your doorstep with just a few taps. Experience the future of grocery shopping at your fingertips.",
    category: "game",
    liveLink: "https://play.google.com/store/apps/details?id=com.aamarbazaar.grocery.rangia.delivery&pli=1",
    codeLink: "https://www.fillosoft.com/",
  },
];

export default Items;
